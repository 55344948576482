<template>
    <div class="inner-section">
      <card>
        <!-- search section start -->
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('teaGardenService.net_tea_type_production_report') }} {{ $t('globalTrans.search') }}</h4>
        </template>
        <template v-slot:searchBody>
          <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off">
              <b-row>
                      <!-- year -->
                      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <ValidationProvider name="year" vid="year" rules="required|min_value:1">
                          <b-form-group
                            label-for="year"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            plain
                            v-model="search.year"
                            :options="yearList"
                            id="year"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <!-- from date -->
                      <b-col xs="12" sm="12" md="4">
                        <ValidationProvider name="Start date" vid="from_date" v-slot="{ errors }" :rules="{required: false}">
                          <b-form-group
                            label-for="from_date">
                            <template v-slot:label>
                              {{ $t('globalTrans.start_date') }}
                            </template>
                            <date-picker
                              id="from_date"
                              v-model="search.from_date"
                              class="form-control"
                              :placeholder="$t('globalTrans.select_date')"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </date-picker>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <!-- to date -->
                      <b-col xs="12" sm="12" md="4">
                        <ValidationProvider name="To date" vid="to_date" v-slot="{ errors }" :rules="{required: false}">
                          <b-form-group
                            label-for="to_date">
                            <template v-slot:label>
                              {{ $t('globalTrans.to_date') }}
                            </template>
                            <date-picker
                              id="to_date"
                              v-model="search.to_date"
                              class="form-control"
                              :placeholder="$t('globalTrans.select_date')"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </date-picker>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <!-- division -->
                      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <ValidationProvider name="Division Name" vid="division_id" :rules="{required: false}">
                          <b-form-group
                              label-for="division_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('globalTrans.division') }}
                              </template>
                              <v-select
                                multiple
                                plain
                                v-model="search.division_id"
                                :options="divisionList"
                                id="division_id"
                                :reduce="item => item.value"
                                label="text"
                                :placeholder="$t('globalTrans.select')"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :filter-by="myFilter"
                              >
                              </v-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <!-- district -->
                      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <ValidationProvider name="District Name" vid="district_id" :rules="{required: false}">
                            <b-form-group
                                label-for="district_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('globalTrans.district') }}
                                </template>
                                <v-select
                                  multiple
                                  plain
                                  v-model="search.district_id"
                                  :options="districtList"
                                  id="district_id"
                                  :reduce="item => item.value"
                                  label="text"
                                  :placeholder="$t('globalTrans.select')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :filter-by="myFilter"
                                >
                                </v-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <!-- citycorporation -->
                      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <ValidationProvider name="Upazila Name" vid="city_corporation_id" :rules="{required: false}">
                            <b-form-group
                                label-for="city_corporation_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('globalTrans.city_corporation') }}
                                </template>
                                <v-select
                                  multiple
                                  plain
                                  v-model="search.city_corporation_id"
                                  :options="citycorporationList"
                                  id="city_corporation_id"
                                  :reduce="item => item.value"
                                  label="text"
                                  :placeholder="$t('globalTrans.select')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :filter-by="myFilter"
                                >
                                </v-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <!-- upazila -->
                      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <ValidationProvider name="Upazila Name" vid="upazila_id" :rules="{required: false}">
                            <b-form-group
                                label-for="upazila_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('globalTrans.upazila') }}
                                </template>
                                <v-select
                                  multiple
                                  plain
                                  v-model="search.upazila_id"
                                  :options="upazilaList"
                                  id="upazila_id"
                                  :reduce="item => item.value"
                                  label="text"
                                  :placeholder="$t('globalTrans.select')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :filter-by="myFilter"
                                >
                                </v-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <!-- return type -->
                      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <ValidationProvider name="Return Type" vid="return_type" :rules="{required: false}">
                          <b-form-group
                              label-for="return_type"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('teaGardenConfig.garden_type') }}
                              </template>
                              <b-form-select
                              plain
                              v-model="search.return_type"
                              :options="returnTypeList"
                              id="return_type"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <!-- valley -->
                      <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="search.return_type === 1">
                          <ValidationProvider name="Valley Name" vid="valley_id" :rules="{required: false}">
                            <b-form-group
                                label-for="valley_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('teaGardenConfig.valley_name') }}
                                </template>
                                <b-form-select
                                plain
                                v-model="search.valley_id"
                                :options="valleyList"
                                id="valley_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <!-- garden -->
                      <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="search.return_type === 1">
                          <ValidationProvider name="Garden Name" vid="garden_id" :rules="{required: false}">
                            <b-form-group
                                label-for="garden_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('teaGardenConfig.garden_name') }}
                                </template>
                                <v-select
                                  multiple
                                  plain
                                  v-model="search.garden_id"
                                  :options="gardenList"
                                  id="garden_id"
                                  :reduce="item => item.value"
                                  label="text"
                                  :placeholder="$t('globalTrans.select')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :filter-by="myFilter"
                                >
                                </v-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <!-- bought leaf factory -->
                      <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="search.return_type === 2">
                          <ValidationProvider name="City Corporation Name" vid="factory_id" :rules="{required: false}">
                            <b-form-group
                                label-for="factory_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('teaGardenConfig.factory_name') }}
                                </template>
                                <v-select
                                  multiple
                                  plain
                                  v-model="search.factory_id"
                                  :options="boughtLeafFactoryList"
                                  id="factory_id"
                                  :reduce="item => item.value"
                                  label="text"
                                  :placeholder="$t('globalTrans.select')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :filter-by="myFilter"
                                >
                                </v-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <!-- search button -->
                      <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <b-button size="sm" variant="primary" class="mt-20" type="submit">
                          <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                        </b-button>
                      </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </template>
        <!-- search section end -->
      </card>
      <b-row>
        <b-col md="12" v-if="showData">
          <body-card>
                <!-- table section start -->
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{ $t('teaGardenService.net_tea_type_production_report') }} {{ $t('globalTrans.list') }}</h4>
                </template>
                <template v-slot:headerAction>
                  <a href="javascript:" class="btn-add" @click="pdfExport">
                     {{ $t('globalTrans.export_pdf') }}
                  </a>

                  <a
                      style="color: white"
                      title="Excel"
                      class="btn-add btn-warning float-right"
                      :href="excelParams"
                      target="_blank"
                    >
                      {{ $t('globalTrans.export_excel') }}
                    </a>

                </template>
                <template v-slot:body>
                  <b-overlay :show="loadingState">
                    <b-row>
                      <b-col>
                        <list-report-head :base-url="teaGardenServiceBaseUrl" uri="/configuration/report-heading/detail" :org-id="5" :service-id="27">
                          <template v-slot:projectNameSlot>
                            {{ }}
                          </template>
                          {{ $t('teaGardenService.net_tea_type_production_report') }}
                        </list-report-head>
                      </b-col>
                    </b-row>
                    <template>
                      <div>
                        <div class="text-black mb-4 mt-2">
                          <b-row>
                          <b-col md="4">
                            {{ $t('globalTrans.year') }}: <strong>{{ search.year ? $n(search.year, { useGrouping: false }) : $t('globalTrans.all') }}</strong>
                          </b-col>
                          <b-col md="4">
                            {{ $t('globalTrans.division') }}: <strong>{{ search.division_id.length ? getDivisionName(search.division_id) : $t('globalTrans.all') }}</strong>
                          </b-col>
                          <b-col md="4">
                            {{ $t('globalTrans.district') }}: <strong>{{ search.district_id.length ? getDistrictName(search.district_id) : $t('globalTrans.all') }}</strong>
                          </b-col>
                          <b-col md="4">
                            {{ $t('globalTrans.city_corporation') }}: <strong>{{ search.city_corporation_id.length ? getCityCorporationName(search.city_corporation_id) : $t('globalTrans.all') }}</strong>
                          </b-col>
                          <b-col md="4">
                            {{ $t('globalTrans.upazila') }}: <strong>{{ search.upazila_id.length ? getUpazilaName(search.upazila_id) : $t('globalTrans.all') }}</strong>
                          </b-col>
                          <b-col md="4" v-if="search.return_type">
                            {{ $t('teaGardenConfig.garden_type') }}: <strong>{{ search.return_type ? getReturnType(search.return_type) : $t('globalTrans.all') }}</strong>
                          </b-col>
                          <b-col md="4" v-if="search.return_type === 1 && search.valley_id">
                            {{ $t('teaGardenConfig.valley_name') }}: <strong>{{ search.valley_id ? getValleyName(search.valley_id) : $t('globalTrans.all') }}</strong>
                          </b-col>
                          <b-col md="4" v-if="search.return_type === 1 && search.garden_id.length">
                            {{ $t('teaGardenConfig.garden_name') }}: <strong>{{ search.garden_id.length ? getGardenName(search.garden_id) : $t('globalTrans.all') }}</strong>
                          </b-col>
                          <b-col md="4" v-if="search.return_type === 2 && search.factory_id.length">
                            {{ $t('teaGardenConfig.factory_name') }}: <strong>{{ search.factory_id.length ? getFactoryName(search.factory_id) : $t('globalTrans.all') }}</strong>
                          </b-col>
                        </b-row>
                        </div>
                        <p class="text-center text-black" v-if="search.from_date && search.to_date">{{search.from_date | dateFormat}} - {{search.to_date | dateFormat}}</p>
                        <div class="table-responsive">
                          <b-table-simple class="tg mt-3" bordered striped hover small caption-top responsive :emptyText="$t('globalTrans.noDataFound')">
                            <b-thead>
                              <b-tr>
                                <b-th class="text-center">{{ $t('globalTrans.district') }}</b-th>
                                <b-th class="text-center" v-if="search.return_type === 1">{{ $t('teaGardenService.valley') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenService.garden_factory_name') }}</b-th>
                                <b-th class="text-center" v-for="(item, index) in monthList" :key="'month-' + index">
                                  {{ currentLocale === 'en' ? item.text_en.substring(0, 3) : item.text_bn }}
                                </b-th>
                                <b-th class="text-center">{{ $t('globalTrans.total') }}</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <template v-if="datas.district_info.length">
                                <template v-for="(district, districtIndex) in datas.district_info">
                                  <template v-for="(valley, valleyIndex) in district.valley_info">
                                    <template v-for="(gardenFact, gardenIndex) in valley.garden_fact_info">
                                        <b-tr :key="'dt-'+ districtIndex+'-vt-'+valleyIndex+'-gt-'+gardenIndex">
                                          <slot v-if="valleyIndex === 0 && gardenIndex === 0">
                                            <b-td class="text-center" :rowspan="districtRowSpan(district.valley_info)">
                                              {{ getDistrictName(district.district_id) }}
                                            </b-td>
                                          </slot>
                                          <slot v-if="gardenIndex === 0 && search.return_type === 1">
                                            <b-td class="text-center" :rowspan="valley.garden_fact_info.length">
                                              {{ getValleyName(valley.valley_id) }}
                                            </b-td>
                                          </slot>
                                          <b-td class="text-center">{{ gardenFact.return_type === 1 ? getGardenName(gardenFact.garden_fact_id) : getFactoryName(gardenFact.garden_fact_id) }}</b-td>
                                          <b-td v-for="(monthInfo, monthIndex) in gardenFact.month_info" :key="monthIndex">
                                            {{ $n(monthInfo.total_production, { useGrouping: false }) }}
                                          </b-td>
                                          <b-td class="text-center">{{ $n(gardenFact.month_total_production, { useGrouping: false }) }}</b-td>
                                        </b-tr>
                                      </template>
                                  </template>
                                </template>
                                <template>
                                  <b-tr>
                                    <b-td colspan="3" v-if="search.return_type === 1" class="text-center">{{ $t('globalTrans.total') }}</b-td>
                                    <b-td colspan="2" v-else-if="search.return_type === 2" class="text-center">{{ $t('globalTrans.total') }}</b-td>
                                    <b-td colspan="2" v-else class="text-center">{{ $t('globalTrans.total') }}</b-td>
                                    <b-td v-for="(totalMonth, totalIndex) in datas.month_total_info" :key="totalIndex">
                                      {{ $n(totalMonth.total_production, { useGrouping: false }) }}
                                    </b-td>
                                    <b-td>{{ $n(datas.all_month_total_production, { useGrouping: false }) }}</b-td>
                                  </b-tr>
                                </template>
                              </template>
                              <template v-else>
                                <b-tr>
                                  <b-td colspan="16" class="text-center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                </b-tr>
                              </template>
                            </b-tbody>
                          </b-table-simple>
                        </div>
                      </div>
                    </template>
                  </b-overlay>
                </template>
                <!-- table section end -->
          </body-card>
        </b-col>
      </b-row>
    </div>
  </template>
  <script>
  import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
  import { netTeaProductionApi } from '../../api/routes'
  import ModalBaseMasterList from '@/mixins/list'
  import ListReportHead from '@/components/custom/TeaGardenReportHead.vue'
  import excel from 'vue-excel-export'
  import { helpers } from '@/utils/helper-functions'
  import Vue from 'vue'
  import { ValidationObserver } from 'vee-validate'
  Vue.use(excel)
  export default {
    mixins: [ModalBaseMasterList],
    components: {
    ListReportHead,
        ValidationObserver
    },
    data () {
      return {
        teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        valid: null,
        search: {
          made_tea_type_id: 0,
          division_id: [],
          district_id: [],
          upazila_id: [],
          city_corporation_id: [],
          return_type: 0,
          valley_id: 0,
          garden_id: [],
          factory_id: [],
          year: new Date().getFullYear(),
          month: 0
        },
        datas: {
          district_info: [],
          month_total_info: []
        },
        searchHeaderData: {
          garden_name_en: '',
          garden_name_bn: ''
        },
        showData: false,
        districtList: [],
        upazilaList: [],
        gardenList: [],
        citycorporationList: []
      }
    },
    created () {
      this.getGardenList()
      this.getBoughtLeafFactoryList()
    },
    watch: {
      'search.limit': function (newVal, oldVal) {
          if (newVal !== oldVal) {
            this.loadData()
          }
        },
        'search.division_id': function (newVal, oldVal) {
          if (newVal !== oldVal) {
            this.getDistrictList(newVal)
            this.getBoughtLeafFactoryList()
            this.getGardenList()
          } else {
            this.districtList = []
          }
        },
        'search.district_id': function (newVal, oldVal) {
          if (newVal !== oldVal) {
            this.getUpazilaList(newVal)
            this.getCityCorporationList(newVal)
            this.getBoughtLeafFactoryList()
            this.getGardenList()
          } else {
            this.upazilaList = []
            this.citycorporationList = []
          }
        },
        'search.upazila_id': function (newVal, oldVal) {
            this.getBoughtLeafFactoryList()
            this.getGardenList()
        },
        'search.city_corporation_id': function (newVal, oldVal) {
            this.getBoughtLeafFactoryList()
            this.getGardenList()
        },
        'search.valley_id': function (newVal, oldVal) {
          this.getGardenList()
        },
        currentLocale: function (newVal, oldVal) {
          if (this.search.division_id.length) {
            this.getDistrictList(this.search.division_id)
          }

          if (this.search.district_id.length) {
            this.getUpazilaList(this.search.district_id)
            this.getCityCorporationList(this.search.district_id)
          }

          if (this.search.valley_id) {
            this.getGardenList()
          }
        }
    },
    computed: {
      divisionList () {
          return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
        },
        valleyList () {
          return this.$store.state.TeaGardenService.commonObj.masterValleyList.filter(item => item.status === 1)
        },
        monthList () {
          return this.$store.state.commonObj.monthList.map(item => {
            return Object.assign({}, item, { text: this.currentLocale === 'en' ? item.text_en : item.text_bn })
          })
        },
        yearList () {
          return helpers.getYearList()
        },
        returnTypeList () {
          return this.$store.state.TeaGardenService.commonObj.returnTypeList.map(item => {
            return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
          })
        },
        currentLocale () {
            return this.$i18n.locale
        },
        excelParams () {
          const lang = this.$i18n.locale // Get the language from $i18n
          const queryParams = Object.keys(this.search)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(this.search[key])}`)
            .join('&')
          return `${teaGardenServiceBaseUrl}btb/return-two-report/net-tea-production-excel/?${queryParams}&lang=${lang}`
        }
    },
    methods: {
      myFilter: (option, text, search) => {
        const temp = search.toLowerCase()
        return option.text_en.toLowerCase().indexOf(temp) > -1 ||
        option.text_bn.toLowerCase().indexOf(temp) > -1
      },
      districtRowSpan (checkData) {
        let total = 0
        checkData.forEach((element, key) => {
          element.garden_fact_info.forEach((element2, key2) => {
            total++
          })
        })
        return total
      },
      async pdfExport () {
        const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.search)
        const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 27)
        if (service !== undefined) {
          if (service.office_type_id) {
            params.office_type_id = service.office_type_id
          }
          if (service.office_id) {
            params.office_id = service.office_id
          }
        }
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, netTeaProductionApi, params)
        var blob = new Blob([result], {
          type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },

      searchData () {
        this.loadData()
      },
      async loadData () {
        this.showData = true
        const params = Object.assign({}, this.search)
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getData(teaGardenServiceBaseUrl, netTeaProductionApi, params)
        if (result.success) {
          this.datas = result.data
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      getDivisionName (ids) {
        if (Array.isArray(ids)) {
          var text = []
          ids.forEach(id => {
            const obj = this.$store.state.CommonService.commonObj.divisionList.find(item => item.value === parseInt(id))
            if (obj) {
              var value = obj?.text
              text.push(value)
            }
          })
          return text.join(', ')
        } else {
          const data = this.$store.state.CommonService.commonObj.divisionList.find(item => item.value === ids)
          return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
        }
      },
      getGardenName (ids) {
        if (Array.isArray(ids)) {
          var text = []
          ids.forEach(id => {
            const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === parseInt(id))
            if (obj) {
              var value = obj?.text
              text.push(value)
            }
          })
          return text.join(', ')
        } else {
          const data = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(data => data.value === ids)
          return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
        }
      },
      getValleyName (id) {
        const data = this.$store.state.TeaGardenService.commonObj.masterValleyList.find(data => data.value === id)
        return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
      },
      getDistrictName (ids) {
        if (Array.isArray(ids)) {
          var text = []
          ids.forEach(id => {
            const obj = this.$store.state.CommonService.commonObj.districtList.find(item => item.value === parseInt(id))
            if (obj) {
              var value = obj?.text
              text.push(value)
            }
          })
          return text.join(', ')
        } else {
          const data = this.$store.state.CommonService.commonObj.districtList.find(data => data.value === ids)
          return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
        }
      },
      getUpazilaName (ids) {
        if (Array.isArray(ids)) {
          var text = []
          ids.forEach(id => {
            const obj = this.$store.state.CommonService.commonObj.upazilaList.find(item => item.value === parseInt(id))
            if (obj) {
              var value = obj?.text
              text.push(value)
            }
          })
          return text.join(', ')
        } else {
          const data = this.$store.state.CommonService.commonObj.upazilaList.find(data => data.value === ids)
          return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
        }
      },
      getCityCorporationName (ids) {
        if (Array.isArray(ids)) {
          var text = []
          ids.forEach(id => {
            const obj = this.$store.state.CommonService.commonObj.cityCorporationList.find(item => item.value === parseInt(id))
            if (obj) {
              var value = obj?.text
              text.push(value)
            }
          })
          return text.join(', ')
        } else {
          const data = this.$store.state.CommonService.commonObj.cityCorporationList.find(data => data.value === ids)
          return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
        }
      },
      getFactoryName (ids) {
        if (Array.isArray(ids)) {
          var text = []
          ids.forEach(id => {
            const obj = this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.find(item => item.value === parseInt(id))
            if (obj) {
              var value = obj?.text
              text.push(value)
            }
          })
          return text.join(', ')
        } else {
          const data = this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.find(data => data.value === ids)
          return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
        }
      },
      getDistrictList (divisionIds) {
        this.districtList = this.$store.state.CommonService.commonObj.districtList.filter(item => divisionIds.includes(item.division_id)).map(el => {
              return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
        })

        if (this.search.district_id.length) {
          const arr = []
          this.search.district_id.forEach(id => {
            const obj = this.districtList.find(obj => obj.value === parseInt(id))
            if (obj !== undefined) {
              arr.push(id)
            }
          })
          this.search.district_id = arr
        }
      },
      getCityCorporationList (districtIds) {
        this.citycorporationList = this.$store.state.CommonService.commonObj.cityCorporationList.filter(item => districtIds.includes(item.district_id)).map(el => {
              return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
        })

        if (this.search.city_corporation_id.length) {
          const arr = []
          this.search.city_corporation_id.forEach(id => {
            const obj = this.citycorporationList.find(obj => obj.value === parseInt(id))
            if (obj !== undefined) {
              arr.push(id)
            }
          })
          this.search.city_corporation_id = arr
        }
      },
      getUpazilaList (districtIds) {
        this.upazilaList = this.$store.state.CommonService.commonObj.upazilaList.filter(item => districtIds.includes(item.district_id)).map(el => {
              return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
        })

        if (this.search.upazila_id.length) {
          const arr = []
          this.search.upazila_id.forEach(id => {
            const obj = this.upazilaList.find(obj => obj.value === parseInt(id))
            if (obj !== undefined) {
              arr.push(id)
            }
          })
          this.search.upazila_id = arr
        }
      },
      getReturnType (id) {
        const obj = this.$store.state.TeaGardenService.commonObj.returnTypeList.find(item => item.value === id)
        return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getGardenList () {
        this.gardenList = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
        // search in division
        if (this.search.division_id.length) {
          this.gardenList = this.gardenList.filter(item => this.search.division_id.includes(item.division_id))
        }
        // search in district
        if (this.search.district_id.length) {
          this.gardenList = this.gardenList.filter(item => this.search.district_id.includes(item.district_id))
        }
        // search in upazila
        if (this.search.upazila_id.length) {
          this.gardenList = this.gardenList.filter(item => this.search.upazila_id.includes(item.upazila_id))
        }
        // search in city corporation
        if (this.search.city_corporation_id.length) {
          this.gardenList = this.gardenList.filter(item => this.search.city_corporation_id.includes(item.city_corporation_id))
        }
        // search in valley id
        if (this.search.valley_id) {
          this.gardenList = this.gardenList.filter(item => item.valley_id === this.search.valley_id)
        }

        if (this.search.garden_id.length) {
          const arr = []
          this.search.garden_id.forEach(id => {
            const obj = this.gardenList.find(obj => obj.value === parseInt(id))
            if (obj !== undefined) {
              arr.push(id)
            }
          })
          this.search.garden_id = arr
        }
      },
      getBoughtLeafFactoryList () {
        this.boughtLeafFactoryList = this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(item => item.status === 1 && item.factory_type_id === 2)
        // search in division
        if (this.search.division_id.length) {
          this.boughtLeafFactoryList = this.boughtLeafFactoryList.filter(item => this.search.division_id.includes(item.division_id))
        }
        // search in district
        if (this.search.district_id.length) {
          this.boughtLeafFactoryList = this.boughtLeafFactoryList.filter(item => this.search.district_id.includes(item.district_id))
        }
        // search in upazila
        if (this.search.upazila_id.length) {
          this.boughtLeafFactoryList = this.boughtLeafFactoryList.filter(item => this.search.upazila_id.includes(item.upazila_id))
        }
        // search in city corporation
        if (this.search.city_corporation_id.length) {
          this.boughtLeafFactoryList = this.boughtLeafFactoryList.filter(item => this.search.city_corporation_id.includes(item.city_corporation_id))
        }

        if (this.search.factory_id.length) {
          const arr = []
          this.search.factory_id.forEach(id => {
            const obj = this.boughtLeafFactoryList.find(obj => obj.value === parseInt(id))
            if (obj !== undefined) {
              arr.push(id)
            }
          })
          this.search.factory_id = arr
        }
      }
    }
  }
  </script>
